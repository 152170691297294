<template>
  <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="placements" :fields="fields" no-local-sorting @sort-changed="sortChangeEmit">
<!--    <template #cell(manager_dsp)="row">-->
<!--      <span>{{ row.item.manager.name }}</span>-->
<!--    </template>-->

    <template #cell(id)="row">
      <p style="word-break: normal; margin: 0;">{{ row.item.id }}</p>
    </template>

    <template #cell(type)="row">
      <!--      {{ row.item.advertisement_type.map((type) => type.name).join(', ') }}-->
      <b-badge v-for="type of row.item.advertisement_types" :key="type.id" class="badge" variant="primary">
        {{ type.name }}
      </b-badge>
    </template>

    <template #cell(advertisers)="row">
      <p class="placements__advertiser" v-for="advertiser of row.item.advertisers">{{ advertiser.name }}</p>
    </template>

    <template #cell(publisher)="row">
      <p class="placements__advertiser">{{ row.item.publisher.name }}</p>
    </template>

    <template #cell(publisher_commission)="row">
      {{ row.item.publisher_commission_dynamic ? `${row.item.publisher_commission_dynamic}%` : row.item.publisher_commission_static }}
    </template>

    <template #cell(publisher_commission_type)="row">
      {{row.item.publisher_commission_type.name}}
    </template>

    <template #cell(priority_by_income)="row">
            <b-badge pill :variant="row.item.priority_by_income ? 'light-success' : 'light-danger'">
              {{ row.item.priority_by_income ? 'Включен' : 'Отключен' }}
            </b-badge>
<!--      <b-form-checkbox v-model="row.item.is_active" variant="success" switch @change="handleToggleActive($event, row.item)" />-->
    </template>


    <template #cell(is_active)="row">
<!--      <b-badge pill :variant="row.item.is_active ? 'light-success' : 'light-danger'">-->
<!--        {{ row.item.is_active ? 'Активен' : 'Неактивен' }}-->
<!--      </b-badge>-->
      <b-form-checkbox v-model="row.item.is_active" variant="success" switch @change="handleToggleActive($event, row.item)" />
    </template>

    <template #cell(actions)="row">
      <b-dropdown right variant="flat-primary" no-caret>
        <template #button-content>
          <more-vertical-icon />
        </template>

        <b-dropdown-item @click="handleEditPlacement(row.item.id)"> Редактировать</b-dropdown-item>

        <b-dropdown-item @click="handleCopyPlacement(row.item.id)">Дублировать</b-dropdown-item>

        <b-dropdown-item :to="{ name: 'advertising-campaigns', params: { placements: [row.item.id] }}">РК</b-dropdown-item>

        <b-dropdown-item v-if="can('read', 'manager-statistic')" :to="{ name: 'manager-statistic', params: { placements: [row.item.id] }}">Статистика</b-dropdown-item>

        <b-dropdown-item @click="toggleShowCode(row.item.id)">Код установки</b-dropdown-item>

        <b-dropdown-item @click="toggleShowVast(row.item.id)">Получить VAST</b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { BTable, BCard, BBadge, BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'
import {useUtils as useAclUtils} from "@core/libs/acl"

const fields = [
  { key: 'id', label: 'ID', sortable: true },
  { key: 'name', label: 'Имя', sortable: true },
  { key: 'type', label: 'Тип' },
  { key: 'advertisers', label: 'Рекламодатели'},
  { key: 'publisher', label: 'Паблишер' },
  { key: 'publisher_commission', label: 'CPM' },
  { key: 'priority_by_income', label: 'Автодоход', sortable: true },
  { key: 'is_active', label: 'Статус', sortable: true },
  { key: 'actions', label: 'Действие' }
]

export default {
  name: 'PlacementsTable',
  components: {
    BTable,
    BCard,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    MoreVerticalIcon
  },
  data() {
    return {
      fields
    }
  },
  props: {
    placements: {
      type: Array,
      required: true
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      type: Boolean,
    }
  },
  methods: {
    handleEditPlacement(id) {
      this.$emit('edit', id)
    },

    toggleShowCode(id) {
      this.$emit('show-code', id);
    },

    toggleShowVast(id) {
      this.$emit('show-vast', id);
    },

    handleToggleActive(event, data) {
      this.$emit('toggle-active', data)
    },

    handleCopyPlacement(id) {
      this.$emit('copy-placement', id);
    },
    sortChangeEmit(sort) {
      if(!sort.sortBy) return;
      this.$emit('sort-change', sort);
    },
  },
  setup() {
    const {can} = useAclUtils();

    return {
      can
    }
  }
}
</script>
<style lang="scss">

.badge {
  margin: 3px;
}

.placements {

  &__advertiser {
    margin-bottom: 0.5rem;
  }
}
</style>
