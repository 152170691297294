<template>
  <div>
    <transition name="fade">
      <campaign-full-page-form v-if="isOpenCreateForm" title="Добавить размещение" @close="isOpenCreateForm = false; copyPlacement = null">
        <create-placement-form :data="{placement: copyPlacement}"   @show-code="toggleShowCode($event)" @show-vast="toggleShowVast($event)" @success="handleSuccessForm(); handleClearCopy()" @error="handleErrorForm" />
      </campaign-full-page-form>
    </transition>

    <transition name="fade">
      <campaign-full-page-form v-if="!!editPlacementVisible" title="Редактировать размещение" @close="editPlacementVisible = false">
        <edit-placement-form
            is-visible
            :data="{ placement: editPlacement }"
            @show-code="toggleShowCode($event)"
            @show-vast="toggleShowVast($event)"
            @success="handleSuccessEditForm"
            @error="handleErrorForm"
        />
      </campaign-full-page-form>
    </transition>


    <EmbedCodeModal v-if="showCode" :id="showCodeId" :type="showCodeType" @close="showCode = false">

    </EmbedCodeModal>

    <transition name="fade">
      <div v-if="!isLoadingFilters && !isOpenCreateForm && !editPlacementVisible">
    <div class="placements__filters-wrapper">
    <b-card v-if="!isLoadingFilters" no-body class="placements__filters" :class="{'_loading' : isLoadingFilters}">
      <b-row class="my-2 px-2 d-flex justify-content-between">
        <b-col lg="3" class="mb-1 mb-lg-0">
          <b-input-group>
            <DropDownFilter
                placeholder="Выберите площадку"
                :disabled="isLoadingFilters"
                :on-change="filterChange"
                :facets="platformsList"
                :state="queryObj.platforms"
                is-paginated
                @paginate="handlePaginateList"
                track-by="id"  name="platforms"/>
            <b-input-group-append is-text>
              <CustomIcons name="platforms" />
            </b-input-group-append>
          </b-input-group>

        </b-col>
        <b-col lg="3" class="mb-1 mb-lg-0">
          <b-input-group>
            <DropDownFilter
                placeholder="DSP"
                :disabled="isLoadingFilters"
                :on-change="setAdvertisers"
                :facets="advertisersList"
                :state="queryObj.advertiser_id"
                is-paginated
                @paginate="handlePaginateList"
                track-by="id"  name="dsps"/>
            <b-input-group-append is-text>
              <CustomIcons name="dsps" />
            </b-input-group-append>
          </b-input-group>

        </b-col>
        <b-col lg="4" class="">
          <b-input-group>
            <b-form-input placeholder="Поиск..." v-model="searchRef" @keyup="startSearch" />
            <b-input-group-append is-text>
              <b-icon-search></b-icon-search>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="d-flex justify-content-end" lg="1">
          <b-button variant="primary" @click="isOpenCreateForm = true">Создать</b-button>
        </b-col>
        <b-col lg="2" class="mt-2">
          <b-form-select v-model="queryObj.is_active" :options="activeOptions" @change="setIsActive" />
        </b-col>
        <b-col lg="5" class="mt-2">
          <DropDownFilter
              placeholder="Выберите тип рекламы"
              :disabled="isLoadingFilters"
              :on-change="filterChange"
              :facets="advertisementTypes"
              :state="queryObj.advertisement_types"
              track-by="id"  name="advertisement_types"/>
        </b-col>
        <b-col sm="5" class="mt-2">
          <b-input-group>
            <DropDownFilter
                placeholder="РК"
                :disabled="isLoadingFilters"
                :on-change="filterChange"
                :facets="campaignsList"
                :state="queryObj.campaigns"
                is-paginated
                @paginate="handlePaginateList"
                track-by="id"  name="campaigns"/>
            <b-input-group-append is-text>
              <CustomIcons name="advertising-campaigns" />
            </b-input-group-append>
          </b-input-group>

        </b-col>
      </b-row>
    </b-card>
    </div>

    <b-card no-body>
      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <placements-table :placements="data" :sort-by="sortBy" :sort-desc="sortDesc" @edit="handleEditUser($event); handleEditPlacementVisible()"  handleEditPlatform
          @show-code="toggleShowCode($event)" @show-vast="toggleShowVast($event)" @toggle-active="handlePlacementToggleActive"
          @copy-placement="handleCopyPlacement($event); handleOpenCreateFormVisible()" @sort-change="handleSortChange"/>
        </table-lazy>
      </div>
    </b-card>
      </div>
    </transition>
  </div>
</template>

<script>
import SidebarRight from '@/components/SidebarRight'
import CampaignFullPageForm from "@/components/CampaignFullPageForm"
import TableLazy from '@/components/TableLazy'
import { getToastificationErrorParams, getToastificationSuccessParams } from '@/helpers/toasts'
import { withFormData } from '@/hoc/withFormData'
import {ref} from "@vue/composition-api"
import { BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BFormSelect, BSpinner, BInputGroup, BIconSearch } from 'bootstrap-vue'
import { usePage } from '@/use/page'
import {activatePlacement, createPlacement, deactivatePlacement, editPlacement, fetchPlacements} from '@/api/placements'
import { infiniteScroll } from "@/use/infiniteScroll"
import {queries} from "@/use/queries"
import {search} from "@/use/search"
import PlacementsTable from "@/components/PlacementsTable"
import DropDownFilter from "@/components/DropDownFilter"
import {
  fetchAdvertisementType, fetchAdvertisersList, fetchCampaignsList,
  fetchPlatformsList
} from "@/api/lists"
import {CREATE_PLACEMENT_VALIDATION} from "@/constants/validation"
import PlacementsForm from "@/components/PlacementsForm"
import {activeToggle} from "@/use/actions/activeToggle"
import statisticLists from "@/use/statisticLists"
import {objToQuery} from "@core/utils/utils"
import CustomIcons from "@/components/CustomIcons"

const CreatePlacementForm = withFormData(PlacementsForm, createPlacement, CREATE_PLACEMENT_VALIDATION)
const EditPlacementForm = withFormData(PlacementsForm, editPlacement, CREATE_PLACEMENT_VALIDATION)

export default {
  components: {
    CustomIcons,
    CreatePlacementForm,
    CampaignFullPageForm,
    EditPlacementForm,
    DropDownFilter,
    PlacementsTable,
    BCard,
    BInputGroup,
    BIconSearch,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    BSpinner,
    SidebarRight,
    TableLazy,
    EmbedCodeModal: () => import("./EmbedCodeModal.vue"),
  },
  setup(_, ctx) {

    const store = ctx.root.$store;

    const activeOptions = [
      { value: null, text: 'Все площадки' },
      { value: 1, text: 'Активные' },
      { value: 0, text: 'Неактивные' }
    ]
    const { isLoading, data, fetchPageData, error, loadMoreData, isLoadingMore } = usePage(fetchPlacements, _, ctx, store)
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    const { queryObj } = queries(fetchPageData, ctx, store, 'placements')
    const { searchRef, startSearch } = search(queryObj, store, 'placements')
    const { handleToggleActive } = activeToggle()

    //refs
    const editPlacement = ref(null)
    const copyPlacement = ref(null)
    const isLoadingFilters = ref(false)
    const advertisementTypes = ref([])
    const showCode = ref(false);
    const showCodeId = ref(0);
    const showCodeType = ref('code');

    const {
        campaignsList,
        advertisersList,
        platformsList,
        handlePaginateList,
        handleLoadLists,
        setQuery
    } = statisticLists()

    const sortBy = ref(null)
    const sortDesc = ref(null)


    const handleSortChange = (sort) => {
      sortBy.value = sort.sortBy;
      sortDesc.value = sort.sortDesc;
      queryObj.field = sort.sortBy;
      queryObj.order = sort.sortDesc ? 'desc' : 'asc';
    }

    const allLists = ['dsps', 'campaigns', 'platforms'];

    if (Object.keys(ctx.root.$route.params).length) {
        for (const key in ctx.root.$route.params) {
          setQuery(key, key, ctx.root.$route.params[key]);
        }
        handleLoadLists(allLists, isLoadingFilters);
    } else if (store.getters['placements/GET_STATE_CHECK']) {
      const filters = store.getters['placements/GET_FILTERS'];
      for (const key in filters) {
        if(!(allLists.includes(key))) continue;
        setQuery(key, key, filters[key]);
      }
      handleLoadLists(allLists, isLoadingFilters);
    }
    handleLoadLists(['dsps', 'campaigns', 'platforms'], isLoadingFilters);

    //methods

    const handlePlacementToggleActive = (data) => handleToggleActive(data, deactivatePlacement, activatePlacement)

    const handleEditUser = (id) => {
      editPlacement.value = data.value.find((u) => u.id === id)
    }

    const handleCopyPlacement = (id) => {
      copyPlacement.value = JSON.parse(JSON.stringify(data.value.find((u) => u.id === id)));
      copyPlacement.value.name += '_копия';
      copyPlacement.value.id = null;
    }

    const handleClearCopy = () => {
      copyPlacement.value = null;
    }

    const filterChange = (value, name) => {
      queryObj[name] = value;
      if(!(name === 'advertisement_types')) store.dispatch('placements/setFilter', {name: name, value: value})
    }

    const setAdvertisers = (value) => {
      filterChange(value, 'advertiser_id');
      store.dispatch('placements/setFilter', {name: 'advertiser_id', value: value})
    }

    const setSearch = () => {
      startSearch();
      store.dispatch('placements/setSearch', searchRef.value);
    }

    const setIsActive = (value) => {
      store.dispatch('placements/setIsActive', value);
    }

    const fetchFiltersOptions = () => {
      isLoadingFilters.value = true;

      Promise.all( [fetchAdvertisementType()])
          .then((values => {
              advertisementTypes.value = values[0].data.data;
          }))
          .finally(() => {
            isLoadingFilters.value = false
          })
    }

    fetchFiltersOptions()

    // embed code modal
    const toggleShowCode = (id) => {
      showCodeType.value = 'code';
      showCode.value = true;
      showCodeId.value = id;
    }

    const toggleShowVast = (id) => {
      showCodeType.value = 'vast';
      showCode.value = true;
      showCodeId.value = id;
    }

    // on created

    // fetchFiltersOptions()

    return { isLoading, data, fetchPageData, error, loadMoreData, isLoadingMore, scroll,
    editPlacement,
    queryObj,
    sortBy,
    sortDesc,
    handleSortChange,
    searchRef,
    startSearch,
    filterChange,
    handleClearCopy,
      platformsList,
      advertisementTypes,
    isLoadingFilters,
      setAdvertisers,
    activeOptions,
      setIsActive,
    handleEditUser,
    showCode,
    showCodeId,
    toggleShowCode,
    copyPlacement,
    handlePlacementToggleActive,
      handlePaginateList,
    toggleShowVast,
    handleCopyPlacement,
    showCodeType,
    campaignsList,
    advertisersList,
      setSearch
   }
  },

  data() {
    return {
      isOpenCreateForm: false,
      editPlacementVisible: false,
    }
  },
  methods: {
    handleEditPlacementVisible() {
      this.editPlacementVisible = true
    },
    handleOpenCreateFormVisible() {
      this.isOpenCreateForm = true;
    },
    handleSuccessForm() {
      this.isOpenCreateForm = false;
      this.$toast(getToastificationSuccessParams('Размещение успешно создано'));
      this.searchRef = '';
      this.fetchPageData();
    },

    handleErrorForm(e) {
      this.isOpenCreateForm.value = false;
      this.$toast(getToastificationErrorParams(e.message))
    },

    handleSuccessEditForm() {
      this.fetchPageData(objToQuery(this.queryObj));
      this.editPlacementVisible = false;
      this.$toast(getToastificationSuccessParams('Размещение успешно отредактировано'))
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>
<style lang="scss" scoped>
.placements {

  &__filters-wrapper {
    position: relative;
  }

  &__loader {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 49%;
    width: 100%;
    height: 100%;
  }

  &__filters {

    &._loading {
      filter: blur(4px)
    }
  }
}
</style>
